.CompanyDetailsShaddow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.company {
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.companyLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.companyRight {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.projectCount {
  display: flex;
  align-items: center;
}
.projectCount {
  display: flex;
  align-items: center;
  padding-right: 15px;
}
.CompanyName {
  padding-left: 15px;
}
.CompanyName span {
  font-size: 20px;
}
.nolink {
  text-decoration: none;
  color: unset;
}
.gradient {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 1000px 640px;

  position: relative;
}
.DetailsImg {
  background-position-x: left !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
