.DetailsImg {
  width: 382.22px;
  height: 215px;
  background: url('../../img/logo.svg');
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.Loadinggradient {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);

  position: relative;
}
