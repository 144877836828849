.companyTopBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.CompanyExpandRight {
  align-items: center;
  display: flex;
  padding-left: 40px;
}
.CompanyImgContainer {
  display: flex;
  flex-direction: row;
}
.right {
  padding-right: 25px;
}
.CompanyeditICON {
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 50%;
  padding: 2px;
  background-color: var(--primaryColor);
  color: white;
}
.LogoContainer {
  position: relative;
}
.uploadbutton {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.LogoContainer .DetailsImg {
  transition: all 0.5s;
}
.uploadbutton .p-fileupload-choose,
.uploadbutton .p-fileupload-buttonbar {
  width: 100%;
  height: 100%;
  background-color: var(--primaryColor);
  opacity: 0.8;
}
.uploadbutton .p-fileupload-content,
.uploadbutton button {
  display: none;
}
.invoiceDataLabel{
  display: flex;
  justify-content: center;
}
.invoiceDataLabel span{
  margin:10px
}