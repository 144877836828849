/* ChatBubble.css */

.chat-bubble {
  max-width: 60%;
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 20px;
  background-color: #f0f0f0;
  position: relative;
}

.chat-bubble.own-message {
  align-self: flex-end;
  background-color: #0084ff;
  color: white;
  display: flex;
  flex-direction: column;
}

.chat-bubble::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.chat-bubble.own-message::after {
  right: -8px;
  bottom: 11px;
  border-left: 10px solid #0084ff;
}

.chat-bubble:not(.own-message)::after {
  left: -8px;
  bottom: 10px;
  border-right: 10px solid #f0f0f0;
}
.chatHeader{
  margin-top: 0px;
  color: var(--primaryColor);
}
.chatMessage{
  margin:0px;
  margin-top: 5px;
}
.outerChat{
  display: flex;
  justify-content: end;
}
.outerChat:not(.own-message){
  justify-content: start;
}
.chatTime{
  margin-top: 0px;
  margin-bottom: -12px;
  font-size: 10px;
}