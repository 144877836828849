@font-face {
  font-family: 'PoppinsLight';
  src: url('./Poppins/Poppins-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'PoppinsMedium';
  src: url('./Poppins/Poppins-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'PoppinsRegular';
  src: url('./Poppins/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'PoppinsSemiBold';
  src: url('./Poppins/Poppins-SemiBold.ttf') format('truetype');
}
* {
  font-family: 'PoppinsMedium';
}
.header {
  font-size: 24px;
  font-family: 'PoppinsSemiBold';
}
