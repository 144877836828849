.uploadFileComponent{
    background-color: beige;
    border-radius: 5px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.CustomLogoContainer{
    width: 300px;
}
.CustomLogoContainer .uploadbutton{
    height: unset;
    border-style: dashed;
    border-radius: 15px;
    background-color: transparent;
}
.CustomLogoContainer .p-fileupload-buttonbar{
    background-color: transparent;
}
.CustomLogoContainer .p-fileupload-choose{
    border-color: transparent;
    background-color: transparent;
    color: black;
}
.BackgroundContainer{
    position: absolute;
    background-image: none;
    background-color: transparent!important;

}