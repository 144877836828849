.projectShareHeader {
    padding-left: 50px;
    padding-top: 50px;
}

.projectShareHeader h3 {
    font-size: 30px;
    margin: 0px;
    padding: 0px;

    font-weight: 900;
    text-transform: uppercase;
    color: var(--primaryColor);
}

.projectShareContent {
    padding-left: 50px;
    padding-top: 50px;
}

.projectShareContent h3 {
    font-size: 40px;
    margin: 0px;
    padding: 0px;
    font-weight: 900;
    text-transform: uppercase;
    color: "white";
}