.editButton {
  background-color: green;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.deletedButton {
  background-color: #ef4444;
  border-color: #ef4444;
}
.disabledDelete {
  opacity: 0.6;
}
.groupEditButton {
  display: flex;
  flex-direction: row;
}
.groupEditButton .customButton {
  margin-right: 15px;
}
.innerDiv .p-button {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  color: white;
}
.exportButton:hover {
  background-color: var(--primaryHoverColor) !important;
  border-color: var(--primaryHoverColor) !important;
}
