.menuitem {
  width: calc(100%-20px);
  margin-right: 5px;
  margin-left: 15px;
  display: flex;
  text-decoration: none;
  color: black;
  border-radius: 20px;
  padding: 10px;
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.current {
  background-color: #edf5f5;
}

.current span {
  font-family: 'PoppinsMedium' !important;
}

.menuitem span {
  font-family: 'PoppinsLight';
}

.menuClass {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menuClass span {
  padding-left: 5px;
}

.notificationAction {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.notificationAction a {
  margin: 5px
}

.notificationWrapper {
  margin:5px;
  background-color: lightgray;
  border-radius: 5px;
  padding:15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

}
.decline{
  background-color: rgba(255, 0, 0, 0.416);
  border-color: transparent;
}
.notificationIcon{
  position: relative;
  display: flex;
}
.notificationIcon span{
  position: absolute;
  top:-10px;
  right:-10px;
}