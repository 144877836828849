.profileNameInput{
    display: flex;

}
.profileNameInput>span{
    margin: 10px;
}
.publicProfileSwitch{
    padding-left: 10px;
    padding-bottom: 10px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
}
.publicProfileSwitch .p-inputswitch{
    margin-right: 20px;
}