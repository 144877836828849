:root {
  --menuBarHeight: 70px;
  --sideBarWith: 200px;
  --primaryColor: #006165;
  --primaryHoverColor: #0062659b;
}

.menubar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  z-index: 1;
  top: 0px;
  width: 100%;
  height: var(--menuBarHeight);
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.logoContainer img {
  max-height: 100%;
  height: 20px;
}

.logoContainer {
  display: flex;
  align-self: center;
  padding: 30px;
}

.searchBar {
  width: 80%;
}

.searchBar {
  display: flex !important;
  align-self: center;
  justify-content: center;
}

.searchBar input {
  width: 100%;
}

.BottomContainer {
  padding-top: var(--menuBarHeight);
  display: flex;
  flex-direction: row;
}

.sideBar {
  position: fixed;
  display: flex;
  width: var(--sideBarWith);
  height: 100vh;
  max-height: 100vh;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.innerDiv {
  padding-left: var(--sideBarWith);
  width: 100%;
  padding-right: 20px;
  padding-top: 20px;
  margin-left: 20px;
}

.leftMenu {
  display: flex;
  padding-right: 30px;
  align-self: center;
  align-items: center;
}
.searchBar span {
  width: 80%;
}
.sidebarElement {
  width: 100%;
}
.primaryFontColor {
  color: var(--primaryColor);
}
.h2Text {
  font-family: 'PoppinsMedium';
  font-size: 20px;
}
