.profileMenu {
  display: flex;
  justify-self: end;
  align-items: center;
  padding: 10px;
}

.profileMenu img {
  height: 40px;
  width: 40px;
  background-color: red;
  border-radius: 5px;
}
.noButton {
  border: 0px;
  background-color: transparent;
  display: flex;
  align-items: center;
}
