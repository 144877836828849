.customButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--primaryColor);
  border-radius: 10px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: var(--primaryColor);
  text-decoration: none;
  color: white;
}
.buttonShaddow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.disabledHREF {
  pointer-events: none;
  cursor: default;
}
