.DetailsShaddow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.top-bar {
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
}
.LeftOfImg {
  padding-left: 40px;
  display: flex;
  align-items: center;
  align-self: center;
  flex-direction: column;
}
.timeLeft {
  padding-top: 10px;
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: row;
}
.stopwatchRightText {
  padding-left: 5px;
  display: flex;
  flex-direction: column;
}
.DetailsRight {
  align-items: center;
  display: flex;
  flex-direction: column;
  align-self: center;
}
.invited {
  align-items: center;
  align-self: flex-start;

  display: flex;
}
.invited span {
  padding-left: 5px;
}
.SubTitle {
  padding-top: 10px;
  display: flex;
  align-self: flex-start;
}
.SubTitle span {
  font-family: 'PoppinsRegular';
}
.divider {
  width: 1px;
  border: 1px solid #dfdfdf;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 70px;
  margin-right: 70px;
}
.DetailsMenu {
  position: absolute;
  top: 5px;
  right: 10px;
}
