.uploadFileButton {
    max-width: 400px;
    padding-top: 20px;
    display: flex;
}

.uploadFileButton a {
    margin: 5px;
}

.folderContextButton a {
    margin: 10px
}