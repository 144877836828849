.ProjectTab {
  border: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  justify-content: space-between;
}
.center {
  padding-left: 15px;
}
.center span {
  text-decoration: none;
}
.link {
  text-decoration: none;
  color: black;
}
.left {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}
.right {
  display: flex;
  flex-direction: row;
  justify-self: end;
  align-self: center;
  align-items: center;
}
.centerProjectText {
  display: flex;
  align-items: center;
  font-size: 15px;
  align-content: center;
}
.centerProjectText svg {
  margin-left: 10px;
}
.card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.title {
  font-size: 20px;
}
.subtitle span {
  font-family: 'PoppinsLight';
}

.inactiveProject>div{
  background-color: lightgray!important;
}
.inactiveProject span{
  color:gray!important;
}